import React from "react"
import BackgroundImage from "gatsby-background-image"
import LeftHeadingWithButton from "./leftheadingwithbutton"

const Films = (props) => {
  const data = props.data

  const sources = [
    data.filmsSectionBackgroundImageMobile.fluid,
    {
      ...data.filmsSectionBackgroundImageDesktop.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources} >
      <div className="featured-films container mx-auto t-grid h-full">
        <LeftHeadingWithButton
          sectionTitle={data.filmsSectionEyebrow}
          headingText={data.filmsSectionsHeading}
          buttonText={data.filmsSectionButtonText}
        class="col-span-full lg:col-start-2 lg:col-span-5 flex flex-col justify-between lg:justify-center h-full py-12 pb-16"
        link="/filmography"
        />
      </div>
   </BackgroundImage>
  )
}

export default Films
