import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Welcome from "../components/welcome"
import Films from "../components/films"
import Divisions from "../components/divisions"
import Newsroom from "../components/newsroom"


const IndexPage = ({data}) => {
  const homepageData = data.contentfulHomepage
  return (
    <Layout>
      <SEO title="Home" />
      <Hero data={homepageData} />
      <Welcome data={homepageData} />
      <Films data={homepageData} />
      <Divisions data={homepageData} />
      <Newsroom />
    </Layout>
  )
}

export const query = graphql`
  query homepageData {
    contentfulHomepage {
      heroBackgroundImageDesktop {
        fluid(maxWidth: 1920, quality:85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroBackgroundImageMobile {
        fluid(quality:85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroHeadingPart1
      heroHeadingPart2OrangeText
      heroHeadingPart3
      welcomeSectionHeading
      welcomeSectionParagraph
      welcomeSectionButtonText
      filmsSectionBackgroundImageDesktop {
        fluid(maxWidth: 1920, quality:85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      filmsSectionBackgroundImageMobile {
        fluid(quality:85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      filmsSectionEyebrow
      filmsSectionsHeading
      filmsSectionButtonText
      divisionsSectionEyebrow
      divisionsSectionHeading
      divisionsSlider {
        divisionsList {
          identifier
          divisionName
          divisionDescriptionParagraph {
            divisionDescriptionParagraph
          }
          backgroundImageDesktop {
            fluid(maxWidth: 1920, quality:85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          backgroundImageMobile {
            fluid(quality:85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }`


export default IndexPage