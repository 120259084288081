import React, { useState, useRef, useEffect } from "react"
import Img from "gatsby-image"

const Divisions = (props) => { 
  const data = props.data

  let divisionsList = {}

  // Create object with each division
  data.divisionsSlider.divisionsList.forEach(element => {
    divisionsList[`${element.identifier}`] = {
        "heading": element.divisionName,
        "text": element.divisionDescriptionParagraph.divisionDescriptionParagraph,
        "background": [
          element.backgroundImageMobile.fluid,
          {
            ...element.backgroundImageDesktop.fluid,
            media: `(min-width: 1024px)`,
          },
        ],
      }
  });

  const [activeDivision, setActiveDivision] = useState("film")
  const { heading, text, background } = divisionsList[activeDivision]

  const divisionsMenuRef = useRef(null)
  const divisionButtonRef = useRef(null)

  useEffect(() => {
    const currentButton = divisionButtonRef.current
    const currentMenu = divisionsMenuRef.current

    currentMenu.scrollTo({
      left:
        currentButton.offsetLeft -
        currentMenu.offsetLeft -
        currentMenu.offsetWidth / 2 +
        currentButton.offsetWidth / 2,
      behavior: "smooth",
    })
  }, [activeDivision])

  const renderNavItem = identifier => {
    const isActive = activeDivision === identifier

    return (
      <button
        ref={isActive ? divisionButtonRef : null}
        className="border-none focus:outline-none min-w-max space-y-2"
        onClick={() => setActiveDivision(identifier)}
        key={identifier}
      >
        <div className="h-5 flex items-center justify-center">
          <span className={isActive ? "cta" : "nav"}>
            {divisionsList[identifier].heading.toUpperCase()}
          </span>
        </div>
        <div className={isActive ? "orange-gradient h-1 w-20" : "h-1 w-20"} />
      </button>
    )
  }

  return (
    <div className="relative h-full bg-black z-0">
      <div className="absolute h-full top-0 bottom-0 left-0 right-0 gatsby-background">
        <Img
          className="h-full"
          fluid={background}
        />
      </div>
      <div className="divisions container mx-auto t-grid pt-12 pb-4 lg:pt-20 lg:pb-32 space-y-8 lg:space-y-12">
        <div className="col-span-full xxs:col-span-3 lg:col-start-2 lg:col-span-7 space-y-3">
          <span className="eyebrow">{data.divisionsSectionEyebrow}</span>
          <h2 className="max-w-md">{data.divisionsSectionHeading}</h2>
        </div>

        <nav
          ref={divisionsMenuRef}
          className="col-span-full lg:col-start-2 lg:col-span-9 flex justify-between max-h-16 overflow-x-scroll lg:overflow-x-visible space-x-7 pt-2"
        >
          {Object.keys(divisionsList).map(renderNavItem)}
        </nav>

        <div className="col-span-full lg:col-start-2 lg:col-span-7 xl:col-start-2 xl:col-span-6">
          <div className="space-y-5 lg:h-80 xl:h-72">
            <h3>{heading}</h3>
            <div>
              <p className="body-2 mb-12">{text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Divisions
