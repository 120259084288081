import React from "react"
import { Link } from "gatsby"

const Welcome = (props) => {
  const data = props.data
  return (
    <div className="bg-white bg-welcome-logo-mobile lg:bg-welcome-logo bg-cover bg-bottom">
      <div className="container welcome mx-auto t-grid text-black py-16 space-y-6 lg:items-center">
        <div className={"col-span-full lg:col-start-2 lg:col-span-5"}>
          <h2 className="max-w-md">{data.welcomeSectionHeading}</h2>
        </div>
        <div className="col-span-full lg:col-start-7 lg:col-span-5">
          <div className="col-span-full lg:col-start-7 lg:col-span-5 space-y-10">
            <div>
              <p className="body-2">
                {data.welcomeSectionParagraph}
              </p>
            </div>
            <div>
              <Link to="/about">
                <button className="button-black cta">{data.welcomeSectionButtonText}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome
