import React from "react"
import BackgroundImage from "gatsby-background-image"

const Hero = (props) => {
  const data = props.data
  
  const sources = [
    data.heroBackgroundImageMobile.fluid,
    {
      ...data.heroBackgroundImageDesktop.fluid,
      media: `(min-width: 384px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources} className="hero-background">
      <div className="hero container mx-auto flex pt-36 lg:pt-0 lg:items-center">
        <h1 className="homepage-hero color-white">
          {data.heroHeadingPart1} <span className="orange">{data.heroHeadingPart2OrangeText}</span> {data.heroHeadingPart3}
        </h1>
      </div>
    </BackgroundImage>
  )
}

export default Hero
