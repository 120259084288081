import React from "react"
import { Link } from "gatsby"

const LeftHeadingWithButton = props => {
  return (
    <div className={`${props.class} space-y-6 lg:space-y-12`}>
      <div className="space-y-3">
        <span className="eyebrow">{props.sectionTitle}</span>
        <h2 className="max-w-md">{props.headingText}</h2>
      </div>
      <Link to={props.link}>
        <button className="button-white cta">{props.buttonText}</button>
      </Link>
    </div>
  )
}

export default LeftHeadingWithButton
